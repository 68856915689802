import { FC } from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

export const Footer: FC = () => {
    return <Box
        borderTop={'var(--secondary-main-color) thin solid'}
        display={'flex'}
        component={'footer'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{backgroundColor: 'var(--dark-bg-color)'}}
        paddingY={'4px'}
        paddingX={'10px'}
        height={'55px'}
    >
        <Typography variant={'caption'}>
            Copyright © 2024 SpinSpectra
        </Typography>
    </Box>
}