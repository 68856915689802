import { Box, useMediaQuery } from "@mui/material";
import { FC } from "react";
import Gambler from '../../../assets/gambling_man.webp'
import Typography from "@mui/material/Typography";

export const SplashScreen: FC = () => {
    const normalScreens = useMediaQuery('(min-width:900px)');
    const smallHeight = useMediaQuery('(max-height:560px)')

    const smallText = smallHeight ? '10px' : !normalScreens ? '18px' : '35px'
    const bigText = smallHeight ? '20px' : !normalScreens ? '30px' : '55px'

    console.log(smallText)

    return <Box
        height={'100%'}
        display={'flex'}
        alignItems={normalScreens ? 'center' : 'flex-end'}
        sx={{
                    backgroundImage: normalScreens ? `url(${Gambler}), linear-gradient(to left, rgba(0,0,0,0.9), rgba(0,0,0,0.5))` : `linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0.1)), url(${Gambler}), linear-gradient(to left, rgba(0,0,0,0.9), rgba(0,0,0,0.5))`,
                    backgroundSize: 'auto calc(100vh - 155px)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: normalScreens ? '90%' : 'center'
                }}>
        <Box marginLeft={normalScreens ? '5vw' : 0} width={normalScreens ? '35%' : '100%'} padding={normalScreens ? 0 : '20px'} display={'flex'} flexDirection={'column'} alignItems={normalScreens ? 'flex-start' : 'center'}>

            <Typography fontSize={bigText} textTransform={'uppercase'} fontWeight={'700'} lineHeight={'1'} textAlign={normalScreens ? 'left' : 'center'}>
                Get 20%
            </Typography>
            <Typography fontSize={bigText} textTransform={'uppercase'} fontWeight={'700'} lineHeight={'1'} color={'primary'} textAlign={normalScreens ? 'left' : 'center'}>
                CashBack Bonus
            </Typography>
            <Typography fontSize={smallText} lineHeight={'1'} fontWeight={'700'} textAlign={normalScreens ? 'left' : 'center'}>
                Win big & get extra refund on losses from bets credited instantly on your bonus account
            </Typography>

        </Box>
    </Box>
}