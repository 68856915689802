import React, { FC, useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { usePlayGames } from "../../../features/play_games/usePlayGames";
import { GameCard } from "./GameCard";
import { useAppDispatch } from "../../../store";
import { setOutcomeGames, setSelectedProviderName } from "../../../features/play_games/playGamesSlice";
import { OverlaySpinner } from "../../../components/common/loaders/OverlaySpinner";
import { useUser } from "../../../features/user/useUser";
import { GameObj } from "../../../features/play_games/playGamesModels";
import { GameWindow } from "../../../components/games/GameWindow";
import { LoginDialog } from "../../../components/common/login/LoginDialog";

export const ProviderGames: FC = () => {
    const { token } = useUser()
    const { outcomeGames, isOutcomeGameLoading, startGameSession, isGameSessionLoading, gameSession } = usePlayGames()
    const normalScreens = useMediaQuery('(min-width:1400px)');
    const dispatch = useAppDispatch()
    const [ isLoginDialogOpened, setLoginDialogOpened ] = useState<boolean>(false)

    const clickHandler = (game: GameObj) => {
        return token ? () => {
            if (!isGameSessionLoading) {
                startGameSession(game.internal_id)
            }
        } : () => {setLoginDialogOpened(true)}
    }

    useEffect(() => {
        return () => {
            dispatch(setSelectedProviderName(null))
            dispatch(setOutcomeGames(null))
        }
    }, [ dispatch ])

    return <>
        <Box height={'100%'} display={'flex'} justifyContent={'center'} position={'relative'}>
            <Box
                height={'100%'}
                width={normalScreens ? '1400px' : '100vw'}
                overflow={'auto'}
                padding={'10px'}
                sx={{ scrollbarWidth: 'thin', scrollbarColor: 'rgba(175, 175, 175, 0.08) rgba(256, 256, 256, 0)' }}>
                <Grid container spacing={'8px'}>
                    {outcomeGames && outcomeGames.map(game => <Grid key={`game-id-${game.internal_id}`} xs={6} sm={4} md={3}
                                                                    lg={2}>
                        <GameCard gameObj={game} onClick={clickHandler(game)}/>
                    </Grid>)}
                </Grid>
            </Box>
            {(isOutcomeGameLoading || isGameSessionLoading) && <OverlaySpinner/>}
            {gameSession.SessionUrl && <GameWindow/>}
        </Box>

        {isLoginDialogOpened && <LoginDialog close={() => setLoginDialogOpened(false)}/>}
    </>
}