import React, { FC, useEffect, useState } from "react";
import { Box, Drawer, Hidden } from "@mui/material";
import { useAppDispatch } from "../../store";
import { resetPlayGamesSlice } from "../../features/play_games/playGamesSlice";
import { Route, Routes } from "react-router-dom";
import { SplashScreen } from "./SplashScreen";
import { ProviderGames } from "./providers/ProviderGames";
import { TopHeader } from "../../components/play_game_layout/TopHeader";
import { Footer } from "../../components/play_game_layout/Footer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useUser } from "../../features/user/useUser";
import { SideMenu } from "../../components/play_game_layout/side_menu/SideMenu";

export const HomePage: FC = () => {
    const dispatch = useAppDispatch()
    const [ isDrawerOpen, setDrawerOpen ] = useState(false)
    const { user, token } = useUser()
    const userIsLogged = Boolean(user) && Boolean(token)

    // Function to toggle the state of the drawer (open/close)
    const toggleDrawer = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerOpen(open);
    }

    useEffect(() => {
        return () => {
            dispatch(resetPlayGamesSlice())
        }
    }, [ dispatch ])

    return <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} height={'100vh'} sx={{backgroundColor: 'var(--dark-bg-color)'}}>
        <TopHeader leftAdornment={
            userIsLogged ? <Hidden mdUp>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => setDrawerOpen(!isDrawerOpen)}
                >
                    <MenuIcon/>
                </IconButton>
            </Hidden> : <></>
        }/>

        {userIsLogged && <Hidden mdUp>
            <Drawer
                sx={{
                    top: '50px',
                    '.MuiBackdrop-root': {
                        top: '50px'
                    },
                    '.MuiPaper-root': {
                        top: '50px',
                        backgroundColor: 'var(--dark-bg-color) !important',
                        backgroundImage: 'none'
                    }
                }}
                anchor="left"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
            >
                <SideMenu close={() => setDrawerOpen(false)} inDrawer/>
            </Drawer>
        </Hidden>}

        <Box component={'main'} flexGrow={1} overflow={'hidden'}>
            <Routes>
                <Route path={""} element={<SplashScreen/>}/>
                <Route path={"games"} element={<ProviderGames/>}/>
            </Routes>
        </Box>
        <Footer/>
    </Box>
}