import { Box, Paper } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { GameObj } from "../../../features/play_games/playGamesModels";
import Config from "../../../config";
import Typography from "@mui/material/Typography";

interface GameCardProps {
    gameObj: GameObj
    onClick?: () => void
}

export const GameCard: FC<GameCardProps> = ({ gameObj , onClick}) => {
    const [ width, setWidth ] = useState<number | undefined>(undefined);
    const [ imageHeight, setImageHeight ] = useState<number | undefined>(undefined)
    const componentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const updateWidth = () => {
            if (componentRef.current) {
                const newWidth = componentRef.current.offsetWidth;
                setWidth(newWidth);
                const height = newWidth / 100 * 62.22
                setImageHeight(height)
            }
        };

        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);

    return <Paper ref={componentRef} sx={{ overflow: 'hidden', cursor: Boolean(onClick) ? 'pointer' : 'initial' }} elevation={4} onClick={onClick}>
        <img alt={gameObj.Name} width={`${width}px`} height={`${imageHeight}px`}
             src={gameObj.Pic !== null ? Config.hostDomain + gameObj.Pic : ''}/>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} padding={'8px'} height={'65px'}>
            <Typography textAlign={'center'} fontSize={'14px'}>
                {gameObj.Name}
            </Typography>
        </Box>
    </Paper>
}